import { getOrganisationID } from 'APIHandler';

export const GatewayID = process.env.REACT_APP_API_GATEWAY_ID;

interface APIEndpoints {
  // NEW
  AppAPI?: string;
  NEW_UsersAPI?: string;
  UserUploadAPI?: string;
  NEW_CompositeUsersAPI?: string;
  OrganisationAPI?: string;
  CompositeOrganisationAPI?: string;
  ReportingAPI?: string;
  TilesAPI?: string;
  CompositeTilesAPI?: string;
  AudiencesAPI?: string;
  ReadPresignAPI?: string;
  WritePresignAPI?: string;
  SharedDataPresignAPI?: string;
  CompositeAudiencesAPI?: string;
  AntivirusAPI?: string;
  TranscodeAPI?: string;
  FeaturesAPI?: string;
  CompositeFeaturesAPI?: string;
  ScormAPI?: string;
  NotificationsAPI?: string;
  CompositeNotificationsAPI?: string;

  // Bespoke
  RecognitionAPI?: string;
  JournalsAPI?: string;

  // CrazyDiamond
  ScrapeAPI?: string;
  UsersAPI?: string;
  LibraryAPI?: string;
  CrazyDiamondPresignAPI?: string;
}

const endpoints = (organisationId: string): APIEndpoints => ({
  AppAPI: GatewayID,
  NEW_UsersAPI: `${GatewayID}users/${organisationId}/user/`,
  UserUploadAPI: `${GatewayID}users/${organisationId}/usercsv`,
  NEW_CompositeUsersAPI: `${GatewayID}users/${organisationId}/composite`,
  OrganisationAPI: `${GatewayID}organisation/${organisationId}/`,
  CompositeOrganisationAPI: `${GatewayID}organisation/${organisationId}/composite`,
  ReportingAPI: `${GatewayID}reporting/${organisationId}/`,
  TilesAPI: `${GatewayID}tiles/${organisationId}/tile/`,
  FeaturesAPI: `${GatewayID}features/${organisationId}/`,
  CompositeFeaturesAPI: `${GatewayID}features/${organisationId}/composite`,
  CompositeTilesAPI: `${GatewayID}tiles/${organisationId}/composite`,
  AudiencesAPI: `${GatewayID}audiences/${organisationId}/`,
  CompositeAudiencesAPI: `${GatewayID}audiences/${organisationId}/composite`,
  ReadPresignAPI: `${GatewayID}misc/${organisationId}/readpresign`,
  WritePresignAPI: `${GatewayID}misc/${organisationId}/writepresign`,
  SharedDataPresignAPI: `${GatewayID}misc/${organisationId}/shareddatapresign`,
  AntivirusAPI: `${GatewayID}antivirus/${organisationId}`,
  TranscodeAPI: `${GatewayID}audiovideo/${organisationId}/transcode`,
  ScormAPI: `${GatewayID}scorm/${organisationId}/`,
  NotificationsAPI: `${GatewayID}notification/${organisationId}/`,
  CompositeNotificationsAPI: `${GatewayID}notification/${organisationId}/composite`,

  // Bespoke
  RecognitionAPI: `${process.env.REACT_APP_KUDOS_ENDPOINT}${organisationId}/`,
  JournalsAPI: `${process.env.REACT_APP_JOURNALS_ENDPOINT}/`,

  // CrazyDiamond
  ScrapeAPI: `${process.env.REACT_APP_API_ENDPOINT}/scrape/${organisationId}/`,
  UsersAPI: process.env.REACT_APP_USERS_ENDPOINT,
  CrazyDiamondPresignAPI: `${process.env.REACT_APP_API_ENDPOINT}/presign/${organisationId}/`,
  LibraryAPI: `${process.env.REACT_APP_LIBRARY_ENDPOINT}${organisationId}/`,
});

export type APIName = keyof APIEndpoints;

export const getEndpoint = (apiName: APIName, path = ''): string => {
  const orgId = getOrganisationID();
  const endpoint = endpoints(orgId)[apiName];

  return endpoint + path;
};
