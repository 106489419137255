import * as Sentry from '@sentry/react';
import { Auth, Hub } from 'aws-amplify';
import { acceptOrganisationTerms } from 'api/wrappers/AdminUsersAPI';
import getUsersLocale from 'helpers/getUsersLocale';
import { useAsync } from 'hooks/useAsyncHook';
import Loader from 'components/UI/Loader/Loader';
import { getUser } from 'api/wrappers/AdminUsersAPI';
import { getOrganisationTermsAndConfig } from 'api/wrappers/OrganisationAPI';
import { emptyCombinedUser, fullName } from 'helpers/Utils';
import { removeSavedLoginDetails } from 'helpers/toDesktop';
import { platform } from '@todesktop/client-core';
import { ReactNode, useEffect, createContext, useCallback, useMemo, useContext, useState } from 'react';
import { UserCombinedType } from 'types/users';
import { LocaleTypes } from 'components/Language/locales';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { eventNames } from 'helpers/analytics';
import { usePostHog } from 'posthog-js/react';
import { getUsersActiveFeatures } from 'api/wrappers/FeatureAPI';

export interface AuthContextUser extends UserCombinedType {
  isHubAdmin: boolean;
  isOwner: boolean;
  language: LocaleTypes;
  hasAcceptedTermsAndConditions: boolean;
  name: string;
  organisationPath: string;
  isViewingAsUser: boolean;
}

const checkImpersonation = async (viewAsUserId: string | null) => {
  let canImpersonate = false;
  if (viewAsUserId) {
    try {
      const res = await getUsersActiveFeatures(viewAsUserId);
      canImpersonate = res.items.some((feature) => feature.key === 'user-impersonation');
    } catch {
      canImpersonate = false;
    }
  }
  if (!canImpersonate && viewAsUserId) sessionStorage.removeItem('viewAsUser');

  return canImpersonate;
};

const parseHulerUser = async (cognitoUser: CognitoUser) => {
  const token = cognitoUser.getSignInUserSession()?.getIdToken().getJwtToken();
  const decodedToken = cognitoUser.getSignInUserSession()?.getIdToken().payload;
  const organisationId = decodedToken && decodedToken['custom:organisation_id'] ? decodedToken['custom:organisation_id'] : 'MCG';
  const claims = await JSON.parse(decodedToken?.encodedClaims);
  // Check impersonation values
  const viewAsUserId = sessionStorage.getItem('viewAsUser');
  const canImpersonate = await checkImpersonation(viewAsUserId);
  const userFromApi = await getUser({ organisationId, userId: canImpersonate ? viewAsUserId : claims.userUUID, detail: 'core,extended,private' });
  const hubAdminRoles = ['owner', 'hub-admin'];
  // TODO(sentry): Could not automatically migrate - see https://github.com/getsentry/sentry-javascript/blob/develop/MIGRATION.md#deprecate-hub
  const isHubAdmin = hubAdminRoles.some((role) => userFromApi?.privilegeLevel?.includes(role));
  const orgData = await getOrganisationTermsAndConfig();
  // If there are no terms in the org or policies are disabled, then mark as accepted
  const hasAcceptedTermsAndConditions =
    orgData.terms.itemCount === 0 || !orgData.config.arePoliciesEnabled || orgData.terms?.items[0]?.version === userFromApi?.organisationTermsVersion;

  // Set JWT cookie (required for SCORM content)
  document.cookie = `JWT-Token=${token}; path=/; domain=${window.location.hostname}`;

  Sentry.getCurrentScope().setUser({
    id: userFromApi.id,
  });

  return {
    isHubAdmin,
    isOwner: userFromApi.privilegeLevel === 'owner',
    language: getUsersLocale() ?? 'en-GB',
    hasAcceptedTermsAndConditions,
    name: fullName(userFromApi),
    organisationPath: organisationId,
    ...userFromApi,
    isViewingAsUser: !!canImpersonate,
  } as AuthContextUser;
};

async function bootstrapAuthData() {
  let user: CognitoUser | null = null;
  try {
    // Auth.userPool is apparently a private property however removing this breaks the app ?
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (Auth.userPool) {
      user = await Auth.currentAuthenticatedUser();
      if (user) {
        const hulerUser = await parseHulerUser(user);
        return hulerUser;
      }
    }
    return user;
  } catch (error) {
    return user;
  }
}

interface AuthContextValue {
  user: AuthContextUser;
  login: (authUser: CognitoUser) => Promise<void>;
  logout: (organisationId: string) => Promise<void>;
  logoutAndReset: (organisationId: string) => Promise<void>;
  acceptTermsAndConditions: (version: number) => Promise<void>;
  setTermsNotAccepted: () => Promise<void>;
  setLanguage: (locale: LocaleTypes) => void;
  setViewAsUser: (orgPath: string, viewAsUserId?: string) => void;
}

const AuthContext = createContext<AuthContextValue>({
  user: {
    ...emptyCombinedUser,
    isHubAdmin: false,
    isOwner: false,
    language: 'en-GB',
    hasAcceptedTermsAndConditions: false,
    name: '',
    organisationPath: '',
    isViewingAsUser: false,
  },
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  logoutAndReset: () => Promise.resolve(),
  acceptTermsAndConditions: () => Promise.resolve(),
  setTermsNotAccepted: () => Promise.resolve(),
  setLanguage: () => {},
  setViewAsUser: () => {},
});

interface AuthProviderProps {
  children?: ReactNode;
}

function AuthProvider({ children }: AuthProviderProps) {
  const { data: user, run, setData, isLoading, isError, isIdle, error } = useAsync();
  const posthog = usePostHog();
  const [authListenerActive, setAuthListenerActive] = useState(true);

  useEffect(() => {
    const authDataPromise = bootstrapAuthData();
    run(authDataPromise);
  }, [run]);

  const hubListenerCancel = Hub.listen('auth', ({ payload: { event, data } }) => {
    if (event === 'customOAuthState' && data) {
      // Decode the returned path from customOAuthState
      const redirectPath = decodeURIComponent(data);
      if (redirectPath) {
        window.location.href = redirectPath;
      }
    }
    setAuthListenerActive(false); // Ensure the listener deactivates
  });

  useEffect(() => {
    // Cancel the listener to ensure we don't have potential memory leaks
    if (!authListenerActive) {
      hubListenerCancel();
    }
  }, [authListenerActive, hubListenerCancel]);

  const login = useCallback(
    async (authUser: CognitoUser) => {
      const hulerUser = await parseHulerUser(authUser);
      // Set the org in local storage so we can prefill the org search input
      localStorage.setItem('orgId', hulerUser.organisationPath);
      setData(hulerUser);
    },
    [setData],
  );
  const logout = useCallback(
    (organisationId: string) =>
      Auth.signOut().then(async () => {
        if (platform.todesktop.isDesktopApp()) {
          await removeSavedLoginDetails();
        }
        sessionStorage.removeItem('viewAsUser');
        window.location.replace(`/${organisationId}/login`);
      }),
    [],
  );
  const logoutAndReset = useCallback(
    (organisationId: string) =>
      Auth.signOut().then(async () => {
        if (platform.todesktop.isDesktopApp()) {
          await removeSavedLoginDetails();
        }
        setData(null);
        window.location.replace(`/${organisationId}/passwordreset`);
      }),
    [setData],
  );

  const acceptTermsAndConditions = useCallback(
    async (version: number) => {
      const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      const currentSessionToken = currentSession.getRefreshToken();

      await acceptOrganisationTerms(user.id, version);

      return cognitoUser.refreshSession(currentSessionToken, () => {
        setData({ ...user, hasAcceptedTermsAndConditions: true });
      });
    },
    [setData, user],
  );

  const setTermsNotAccepted = useCallback(
    () =>
      Auth.signOut().then(() => {
        setData(null);
      }),
    [setData],
  );

  const setViewAsUser = (orgPath: string, viewAsUserId?: string) => {
    if (viewAsUserId) {
      sessionStorage.setItem('viewAsUser', viewAsUserId);
    } else {
      sessionStorage.removeItem('viewAsUser');
    }
    window.location.href = `/${orgPath}/hub`;
  };

  const setLanguage = useCallback(
    (locale: LocaleTypes) => {
      setData({ ...user, language: locale });
      posthog.capture(eventNames.core.languageChanged, { language: locale });
      localStorage.setItem('HulerWebsite_Locale', locale);
    },
    [setData, user, posthog],
  );

  const value = useMemo(
    () => ({ user, login, logout, logoutAndReset, acceptTermsAndConditions, setTermsNotAccepted, setLanguage, setViewAsUser }),
    [user, login, logout, logoutAndReset, acceptTermsAndConditions, setTermsNotAccepted, setLanguage],
  );

  if (isLoading || isIdle) {
    return <Loader fullscreen />;
  }

  if (isError) {
    return <div>Error Occurred {error?.message} </div>;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within an Auth Provider`);
  }

  return context;
}

export { AuthProvider, useAuth };
